import { Common } from './common';

export const LayoutModal = 'modal';

export class Modal extends Common {
  constructor(options) {
    const layout = {name: LayoutModal, component: () => import('../Modal.vue'), lockBodyScroll: true};
    super(options, layout)
  }
}
