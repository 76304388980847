<template>
    <form novalidate @submit.prevent="validateStep">
        <div class="md-layout md-gutter">
            <template v-for="field in section.fields">
                <div :class="`md-layout-item ${field.size || columnSize}`" v-if="visible(field.requires)">
                    <TilesField
                            v-if="field.type === 'tiles'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></TilesField>
                    <RadioField
                            v-else-if="field.type === 'radio'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></RadioField>
                    <SelectField
                            v-else-if="field.type === 'select'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></SelectField>
                    <CheckboxField
                            v-else-if="field.type === 'checkbox'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></CheckboxField>
                    <Switch 
                            v-else-if="field.type === 'switch'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    />
                    <TextAreaField
                            v-else-if="field.type === 'textarea'"
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></TextAreaField>
                    <FileField
                            v-else-if="field.type === 'file'"
                            v-bind="field"
                            v-model="form[field.id]"
                            ref="withFiles"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></FileField>
                    <SignPadField 
                            v-else-if="field.type === 'signature'"
                            v-bind="field"
                            v-model="form[field.id]"
                            ref="widthSignature"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></SignPadField>
                    <TextField
                            v-else
                            v-bind="field"
                            v-model="form[field.id]"
                            @input="validateStep(field.id)"
                            :class="getValidationClass(field.id)"
                    ></TextField>
                    <md-tooltip v-if="field.tooltip">{{$t(field.tooltip)}}</md-tooltip>
                </div>
            </template>
        </div>
    </form>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import {
    required,
    email,
    url,
    minLength,
    maxLength,
    sameAs,
    helpers,
  } from 'vuelidate/lib/validators';
  import TextField from './TextField';
  import TextAreaField from './TextAreaField';
  import SelectField from './SelectField';
  import CheckboxField from './CheckboxField';
  import TilesField from './TilesField';
  import RadioField from './RadioField';
  import FileField from './FileField';
  import SwitchField from './SwitchField';
  import SignPadField from './SingPadField';

  export default {
    name: 'Form',
    components: {TilesField, CheckboxField, SelectField, TextField, TextAreaField, RadioField, FileField, SwitchField, SignPadField},
    mixins: [validationMixin],
    props: {
      section: Object,
      form: Object,
      value: Boolean,
    },
    data() {
      return {
        sending: false,
      }
    },
    validations() {
      const form = {}

      if (this.section) {
        this.section.fields.forEach(({id, type, requires, ...options}) => {
          if (!this.visible(requires)) {
            return ;
          }

          if (type === 'signature') {
            form[id] = {
              required: options.required ? _ => !!_ : {},
            }
            return { form };
          }

          form[id] = {
            required: options.required ? required : {},
            sameAs: type === 'checkbox' && options.required ? sameAs( () => options.trueValue || options.value ) : {},
            email: type === 'email' ? email : {},
            url: type === 'url' ? url : {},
            minLength: options.minLength ? minLength(options.minLength) : {},
            maxLength: options.maxLength ? maxLength(options.maxLength) : {},
            regex: options.pattern ? helpers.regex('pattern', new RegExp(options.pattern)) : {},
            maxFilesLimits: type === 'file' && options.limit ? (_) => { console.log('files_limits valid', _); return !_ || _.length <= options.limit;} : {}
          }
        });
      }

      return {
        form
      }
    },
    computed: {
      columnSize() {
        const {cols = 1} = this.section;
        const size = cols === 3 ? 33 : 5 * Math.ceil(20 / cols);
        return `md-xsmall-size-100 md-size-${size}`;
      },
    },
    methods: {
      getValidationClass(fieldName) {
        const field = this.$v.form[fieldName]

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },
      validateStep(id) {
        if (this.$v[id]) {
          this.$v[id].touch();
        }

        console.log('valid', !this.$v.$invalid);
        this.$emit('input', !this.$v.$invalid);
      },
      validate() {
        this.$v.$touch();
        this.$emit('input', !this.$v.$invalid);
      },
      visible(requirements) {
        if (!requirements) {
          return true;
        }

        return Object.keys(requirements)
          .every(key => this.form[key] === requirements[key])
      },

      getFiles() {
        let res = [].concat(...(this.$refs.withFiles ?? []).map(x => x?.getFiles?.() ?? []));
        res = res.concat(...(this.$refs.widthSignature ?? []).map(x => x?.getFiles?.() ?? []));
        return res;
      }
    }
  }
</script>
