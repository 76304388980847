import { Common } from './common';

/*
  Additional options:

width (Int): Width in px
height (Int): Height in px
intro (String): Intro as HTML (could be a banner or an intro text)
avatar (String): Avatar image URL

Additional methods:

showIndicator(Message: String)
Show indicator

hideIndicator()
Hide the indicator.
*/

export const LayoutRobot = 'robot';

export class Robot extends Common {

  constructor(options) {
    const layout = {name: LayoutRobot, component: () => import('../Robot.vue')};
    super(options, layout)
  }

  showIndicator(message) {

  }

  hideIndicator() {

  }
}