import { Common } from './common';
import { Embedded, LayoutEmbedded } from './embedded';
import { Modal, LayoutModal } from './modal';
import { Popup, LayoutPopup } from './popup';
import { Robot, LayoutRobot } from './robot';

export {
  Common,
  Embedded,
  Modal,
  Popup,
  Robot
}

export default function (options) {
  const {style, ...rest} = options;

  switch(style ?? LayoutEmbedded) {
    case LayoutEmbedded: return new Embedded(rest);
    case LayoutModal: return new Modal(rest);
    case LayoutPopup: return new Popup(rest);
    case LayoutRobot: return new Robot(rest);
    default: 
      throw new Error(`Layout ${style} is not supported.`);
  }
}