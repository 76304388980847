<template>
    <md-field>
        <label :for="id">{{$t(label)}}</label>
        <md-file
                :name="id"
                :id="id"
                :accepts="accepts"
                :multiple="limit > 1"
                ref="native"
                @input="$emit('input', $refs.native.model)"
                :disabled="disabled"
        />
        <span class="md-helper-text" v-if="helperText">{{$t(helperText)}}</span>
        <span class="md-error">{{$t(description || 'Validation.genericError')}}</span>
    </md-field>
</template>

<script>
    export default {
      props: {
        id: String,
        label: {},
        disabled: Boolean,
        accepts: {type: [String, Array], default: ''},
        description: {},
        helperText: {},
        limit: {type: Number, default: 1}
      },
      methods: {
        getFiles() {
          return [... this.$refs.native?.$refs?.inputFile?.files ?? [] ]
        }
      }
    }
</script>
