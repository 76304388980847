<template>
    <div class="radio-field">
        <label :for="id">{{$t(label)}}</label>
        <div class="md-layout md-gutter">
            <template v-for="(option, index) in options">
                <md-radio
                        :key="index"
                        :name="id"
                        @change="$emit('input', model)"
                        :value="option.value"
                        v-model="model"
                        :disabled="option.disabled"
                        :title="$t(option.hint)"
                        :class="`md-layout-item md-xsmall-size-100 md-size-${size}`"
                >
                    <label class="md-title">{{ $t(option.label) }}</label>
                </md-radio>
            </template>
        </div>
        <span class="md-helper-text" v-if="helperText">{{$t(helperText)}}</span>
        <span class="md-error" >{{$t(description || 'Validation.genericError')}}</span>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          model: this.value,
        }
      },
      watch: {
        value(value) {
          this.model = value;
        }
      },
      props: {
        id: String,
        label: {},
        disabled: Boolean,
        value: {},
        options: Array,
        description: {},
        cols: {},
        helperText: {},
      },
      computed: {
        size() {
          const num = this.cols || Object.keys(this.options).length;
          return num === 3 ? 33 : 5 * Math.max(5, 20 / num);
        }
      }
    }
</script>

<style>
    .radio-field {
        margin: 16px 0;
    }

    .radio-field .md-radio {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .radio-field .md-error {
      display: none;
    }

    .radio-field.md-invalid .md-error {
      display: initial;
      color: var(--md-theme-default-fieldvariant, #ff1744);
    }

</style>
