<template>
    <md-field>
        <label :for="id">{{$t(label)}}</label>
        <md-input
                :name="id"
                :id="id"
                :autocomplete="id"
                :value="value"
                ref="native"
                @input="$emit('input', $refs.native.model)"
                :disabled="disabled"
        />
        <span class="md-helper-text" v-if="helperText">{{$t(helperText)}}</span>
        <span class="md-error">{{$t(description || 'Validation.genericError')}}</span>
    </md-field>
</template>

<script>
    export default {
      props: {
        id: String,
        label: {},
        disabled: Boolean,
        value: {},
        description: {},
        helperText: {},
      }
    }
</script>
