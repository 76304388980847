<template>
  <md-steppers  md-alternative :md-active-step.sync="active" md-linear md-dynamic-height>
    <md-step
      v-for="(step, index) in steps"
      :key="index"
      :id="step.id"
      :md-label="$t(step.label)"
      :md-done="state[index]"
      :md-editable="!sending && !sent"
    >
      <Step
        :step="step"
        :form="form"
        v-model="state[index]"
        :ref="`step_${index}`"
      ></Step>
      <div style="text-align: right">
        <md-button v-for="(button, index) in getCurrentButtons(step.id)"
          :key="index"
          @click="onButtonClick(button)"
          :class="['md-raised', button.color ? `md-${button.color}` : 'md-primary']"
        >
          {{$t(button.label)}}
          &nbsp;
          <i v-if="button.icon" :class="button.icon"></i>
        </md-button>
      </div>
    </md-step>
    <md-step id="sent" :md-label="$t(submit.tabTitle || 'Finish')" md-icon="fas fa-complete" :md-editable="false" :md-done="sent">
        <div style="text-align: center">
            <md-progress-spinner v-if="sending" class="md-accent" md-mode="indeterminate"></md-progress-spinner>
            <h1 v-if="sent">{{$t(submit.title || 'Actions.Congratulations')}}</h1>
            <div v-if="sent && submit.description" v-html="$t(submit.description)"></div>
        </div>
    </md-step>
  </md-steppers>
</template>

<script>
  import Step from '../components/Step';

  export default {
    name: 'WizzardWidget',
    components: {
      Step,
    },
    data() {
      return {
        active: this.$root.config.widget.frames[0]?.id,
        sending: false,
        sent: false,
        form: {... (this.$root.initial || {})},
        state: this.$root.config.widget.frames.map(() => false),
      }
    },
    computed: {
      steps () { return this.$root.config.widget.frames },
      submit() { return this.$root.config.widget.buttons.find(_ => _.request) },
      prevButton() {
        return this.$root.config.widget.buttons.find(_ => _.action === 'prev')
          || {label: 'Action.prev', action: 'prev', icon: 'fas fa-chevron-right'}
      },
      nextButton() {
        return this.$root.config.widget.buttons.find(_ => _.action === 'next')
          || {label: 'Action.next', action: 'next', icon: 'fas fa-chevron-left'}
      },
      done() { return this.state.every(x => x) },
      formdata() {
        const data = new FormData();
        Object.entries(this.form).forEach(([key, value]) => data.append(key, value));
        this.getFiles().forEach((file, idx) => {
           if (file.signature) {
            data.append(file.name, file.data, file.name);
           } else {
            data.append(`__file_${idx}`, file, file.name);
           }
        });
        return data;
      },
      activeStepIndex() { return this.getStepIndex(this.active); }
    },
    methods: {
      getCurrentButtons(id) {
        const buttons = [];
        if (this.steps.findIndex(({id: _}) => _ === id) > 0) {
          buttons.push(this.prevButton);
        }
        const frame = this.steps.find(_ => _.id === id);
        if (frame?.buttons) {
          buttons.push(...frame.buttons);
        }
        if (!this.isLast(id)) {
          buttons.push(this.nextButton);
        }
        if (this.isLast(id)) {
          buttons.push(this.submit);
        }
        return buttons;
      },
      onButtonClick(button) {
        if (button.action === 'close') {
          this.$root.open = false;
          return
        }

        const stepIndex = this.activeStepIndex;
        if (button.action === 'prev') {
          this.active = (this.steps[stepIndex - 1] || {}).id || this.active;
          return;
        }
        if (button.action === 'next' && !this.isLast(stepIndex)) {
          this.validate(this.active, this.activeStepIndex)
          return;
        }
        if (button.request) {
          this.validate(this.active, this.activeStepIndex)
          return;
        }
      },
      getStepIndex(step) {
        return this.steps.findIndex(({id: _}) => _ === step)
      },
      isLast(id) {
        const current = this.steps.findIndex(({id: _}) => _ === id);
        return !(current + 1 < this.steps.length);
      },
      validate(id, index) {
        if (this.state[id] == null) {
          this.state[id] = true;
          this.$refs[`step_${index}`].forEach(x => x.validate());

          setTimeout(() => this.validate(id, index), 500);
          return;
        }

        const isStepReady = (this.isLast(id) ? this.done : this.state[index]);
        if (!isStepReady) {
          return;
        }

        const current = this.steps.findIndex(({id: _}) => _ === id);
        this.active = (this.steps[current + 1] || {}).id || this.active;

        if (this.isLast(id)) {
          this.finish().then(_ => console.log('DONE'));
        }
      },
      async finish() {
        this.active = 'sent';
        this.sending = true;

        console.log('before onSubmit');
        await this.$root.events?.onSubmit?.(this.form, this.formdata);


        console.log('after onSubmit', this.submit);
        console.log('this.$http', this.$http);
        if (this.submit.request && this.$http) {
          const files = this.getFiles();

          const {url, method = 'post', headers, params = {}, mode, envelope} = this.submit.request;

          if (files.length > 0 && mode === 'json') {
            console.warn('using multipart/form-data');
          }

          const form = mode === 'json' && files.length < 1 ? (envelope ? {[envelope]: this.form} : this.form) : this.formdata;

          try {
            console.log('before request');
            const res = await this.$http?.[method.toLowerCase()](url
                + (url.indexOf('?') >= 0 ? '&' : '?') + 'language=' + this.$root.$i18n.locale,
                form, {headers, params});

            console.log('request result', res);
            this.$root.events?.onSuccess?.();
            this.submit.request?.onSuccess?.();
          } catch (e) {
            if (typeof this.$root.events?.onError === 'function') {
              this.$root.events?.onError(e);
              this.submit.request?.onFailure?.();
            } else {
              throw e;
            }
          }

        }
        this.sending = false;
        this.sent = true;
      },

      getFiles() {
        return [].concat(...([].concat(...Object.values(this.$refs))).map(x => x?.getFiles?.() ?? []))
      }
    }
  }
</script>
