/* Options:
  * container (DOM Element): The container element (default is document.body)
  * data (Object): Initial form data
  * dataUrl (String): Loads
  * defaultLanguage (String): The default language, otherwise the browser language is being detected.
  * languages (Array):  Defines the available languages, e.g.
*/

import Vue from 'vue'
import Main from '../Main.vue'

import VueI18n from 'vue-i18n';
import VueSignaturePad from 'vue-signature-pad';
import VueResource from 'vue-resource';

import Widget from '../widget';

import MdApp from 'vue-material/src/components/MdApp/MdApp'
import MdAppToolbar from 'vue-material/src/components/MdApp/MdAppToolbar'
import MdAppContent from 'vue-material/src/components/MdApp/MdAppContent'
import MdAppDrawer from 'vue-material/src/components/MdApp/MdAppDrawer'
import MdBadge from 'vue-material/src/components/MdBadge/MdBadge'
import MdAutocomplete from 'vue-material/src/components/MdAutocomplete/MdAutocomplete'
import MdAvatar from 'vue-material/src/components/MdAvatar/MdAvatar'
import MdBottomBar from 'vue-material/src/components/MdBottomBar/MdBottomBar'
import MdButton from 'vue-material/src/components/MdButton/MdButton'
import MdCard from 'vue-material/src/components/MdCard/MdCard'
import MdCheckbox from 'vue-material/src/components/MdCheckbox/MdCheckbox'
import MdChips from 'vue-material/src/components/MdChips/MdChips'
import MdContent from 'vue-material/src/components/MdContent/MdContent'
import MdDatepicker from 'vue-material/src/components/MdDatepicker/MdDatepicker'
import MdDialog from 'vue-material/src/components/MdDialog/MdDialog'
import MdDialogAlert from 'vue-material/src/components/MdDialog/MdDialogAlert/MdDialogAlert'
import MdDialogConfirm from 'vue-material/src/components/MdDialog/MdDialogConfirm/MdDialogConfirm'
import MdDialogPrompt from 'vue-material/src/components/MdDialog/MdDialogPrompt/MdDialogPrompt'
import MdDivider from 'vue-material/src/components/MdDivider/MdDivider'
import MdDrawer from 'vue-material/src/components/MdDrawer/MdDrawer'
import MdElevation from 'vue-material/src/components/MdElevation'
import MdEmptyState from 'vue-material/src/components/MdEmptyState/MdEmptyState'
import MdField from 'vue-material/src/components/MdField/MdField'
import MdFile from 'vue-material/src/components/MdField/MdFile/MdFile'
import MdInput from 'vue-material/src/components/MdField/MdInput/MdInput'
import MdSelect from 'vue-material/src/components/MdField/MdSelect/MdSelect'
import MdOption from 'vue-material/src/components/MdField/MdSelect/MdOption'
import MdOptgroup from 'vue-material/src/components/MdField/MdSelect/MdOptgroup'
import MdTextarea from 'vue-material/src/components/MdField/MdTextarea/MdTextarea'
import MdHighlightText from 'vue-material/src/components/MdHighlightText/MdHighlightText'
import MdIcon from 'vue-material/src/components/MdIcon/MdIcon'
import MdImage from 'vue-material/src/components/MdImage/MdImage'
import MdLayout from 'vue-material/src/components/MdLayout'
import MdList from 'vue-material/src/components/MdList/MdList'
import MdListItem from 'vue-material/src/components/MdList/MdListItem/MdListItem'
import MdMenu from 'vue-material/src/components/MdMenu/MdMenu'
import MdMenuItem from 'vue-material/src/components/MdMenu/MdMenuItem'
import MdMenuContent from 'vue-material/src/components/MdMenu/MdMenuContent'
import MdProgressBar from 'vue-material/src/components/MdProgress/MdProgressBar/MdProgressBar'
import MdProgressSpinner from 'vue-material/src/components/MdProgress/MdProgressSpinner/MdProgressSpinner'
import MdRadio from 'vue-material/src/components/MdRadio/MdRadio'
import MdRipple from 'vue-material/src/components/MdRipple/MdRipple'
import MdSnackbar from 'vue-material/src/components/MdSnackbar/MdSnackbar'
import MdSteppers from 'vue-material/src/components/MdSteppers/MdSteppers'
import MdSubheader from 'vue-material/src/components/MdSubheader/MdSubheader'
import MdSwitch from 'vue-material/src/components/MdSwitch/MdSwitch'
import MdTable from 'vue-material/src/components/MdTable/MdTable'
import MdTabs from 'vue-material/src/components/MdTabs/MdTabs'
import MdToolbar from 'vue-material/src/components/MdToolbar/MdToolbar'
import MdTooltip from 'vue-material/src/components/MdTooltip/MdTooltip'
import MdReactive from '~core/utils/MdReactive'
import MdTheme from '~core/MdTheme'


let VueMaterial = Vue => {
  const init = () => {
    let material = new MdReactive({
      ripple: true,
      theming: {},
      locale: {
        startYear: 1900,
        endYear: 2099,
        dateFormat: 'yyyy-MM-dd',
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        shorterDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],
        firstDayOfAWeek: 0,
        cancel: 'Cancel',
        confirm: 'Ok'
      },
      router: {
        linkActiveClass: 'router-link-active'
      }
    })

    Object.defineProperties(material.theming, {
      metaColors: {
        get: () => MdTheme.metaColors,
        set (metaColors) {
          MdTheme.metaColors = metaColors
        }
      },
      theme: {
        get: () => MdTheme.theme,
        set (theme) {
          MdTheme.theme = theme
        }
      },
      enabled: {
        get: () => MdTheme.enabled,
        set (enabled) {
          MdTheme.enabled = enabled
        }
      }
    })

    return material
  }

  if (!Vue.material) {
    Vue.material = init()
    Vue.prototype.$material = Vue.material
  }

  [
    MdApp,
    MdAppContent,
    MdAppDrawer,
    MdAppToolbar,
    MdBadge,
    MdAutocomplete,
    MdAvatar,
    MdBottomBar,
    MdButton,
    MdCard,
    MdCheckbox,
    MdChips,
    MdContent,
    MdDatepicker,
    MdDialog,
    MdDialogAlert,
    MdDialogConfirm,
    MdDialogPrompt,
    MdDivider,
    MdDrawer,
    MdEmptyState,
    MdField,
    MdFile,
    MdInput,
    MdSelect,
    MdOption,
    MdOptgroup,
    MdTextarea,
    MdHighlightText,
    MdIcon,
    MdImage,
    MdList,
    MdListItem,
    MdMenu,
    MdMenuItem,
    MdMenuContent,
    MdProgressBar,
    MdProgressSpinner,
    MdRadio,
    MdRipple,
    MdSnackbar,
    MdSteppers,
    MdSubheader,
    MdSwitch,
    MdTable,
    MdTabs,
    MdToolbar,
    MdTooltip
  ].forEach((MdComponent) => {
    Vue.component(MdComponent.name, MdComponent);
  })
}

function createVueInstance(target, layoutConfig, options, initial = {}, events = {}) {
  Vue.use(VueI18n);
  Vue.use(VueMaterial);
  Vue.use(VueSignaturePad);
  Vue.use(VueResource);
  Vue.http.options.emulateJSON = true;


  Vue.component(layoutConfig.name, layoutConfig.component);

  Vue.config.productionTip = false;

  const locale = options.locale || options.defaultLanguage || Object.keys(options.locales || {}).shift();

  const i18n = new VueI18n({
    locale,
    messages: options.locales,
    silentTranslationWarn: true,
  })

  const resetTo = JSON.stringify(options);

  const id = `formflow-${Math.random().toString(36).substr(2)}`;
  const div = document.createElement('div');
  div.setAttribute('id', id);
  (document.getElementById(target) ?? document.body).appendChild(div);
  const res = new Vue({
    i18n,
    render: h => h(Main),
    data: {
      layoutComponent: layoutConfig,
      open: false,
      config: options,
      initial,
      events,
    },
    created() {

      const i18n = Vue.prototype.$t;
      const that = this;
      Vue.prototype.$t = function (label) {
        if (typeof label === 'object') {
          return label[that.$i18n.locale] || label[Object.keys(label).shift()] || label;
        }
        return i18n.apply(this, arguments);
      }

      this.$i18n.locale = options.defaultLanguage || navigator.language.split('-').shift();
      console.log(this.$i18n.locale);
      this.events?.onLoad?.();
    },
    watch: {
      open(current) {
        if (layoutConfig.lockBodyScroll !== true) {
          return;
        }

        if (current === true) {
          document.body.setAttribute('__overflow', document.body.style.overflow);
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = document.body.getAttribute('__overflow');
          document.body.removeAttribute('__overflow');
        }
      }
    },
    methods: {
      show() {
        this.open = true;
      },
      hide() {
        this.open = false;
      },
      reset() {
        this.config = JSON.parse(resetTo);
        this.config.initial = initial;
        this.open = false;
      }
    }
  });
  res.$mount(`#${id}`);
  return res;
}


export class Common {

  constructor (config, layoutConfig) {
    const {
      container,
      data,
      events,
      widget,
      ...rest
    } = config;

    const options = {
      widget: new Widget.Common(widget),
      ...rest
    }

    const init = () => this.instance = createVueInstance(container, layoutConfig, options, data, events);
    if (document.body) {
      init();
    } else {
      window.addEventListener('DOMContentLoaded', init);
    }
  }

  hide() {
    this.instance?.hide();
  }

  show() {
    this.instance?.show();
  }

  destroy() {
    this.instance?.$destroy();
  }

  addFrame(id, frame) {
    let frames = this.instance.config.widget.frames;
    if (!frames) {
      frames = [];
    }
    frames.push({id, ...frame});
    this.instance.$forceUpdate();
  }

  getFrame(id) {
    return this.instance.config.widget.frames?.find((_, idx) => typeof id === 'number' ? idx !==id : _.id !== id);
  }

  removeFrame(id) {
    this.instance.config.widget.frames = this.instance.config.widget.frames?.filter((_, idx) => typeof id === 'number' ? idx !==id : _.id !== id) ?? [];
    this.instance.$forceUpdate();
  }

  // Returns the merged values of all frames.
  getValues() {
    return {...this.instance.$children[0].form};
  }

  clearData() {
    this.setData({});
  }

  getData() {
    return {...this.instance.$children?.[0]?.form};
  }
  setData(data) {
    this.instance.config.initial = data;
    this.instance.$forceUpdate();
  }
  appendData(data) {
    this.setData({
      ...this.getData(),
      ...data
    });
  }
  reset() {
    this.instance.reset();
  }
  changeLanguage(lang) {
    this.instance.$i18n.locale = lang;
  }
  // Loads options (esp. data and widget) from an URL.
  fromUrl(url, method, params, headers) {
    fetch({url, headers, params, method})
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(({data, widget}) => {
        if (this.instance) {
          this.instance.config.initial = data;
          this.instance.config.widget = widget;
          this.instance.$forceUpdate();
        }
      })
  }
}
