
export const Field = ({type, trueValue, ...options}) => {
  return {
    ...options,
    trueValue: type === 'checkbox' || type === 'switch' ? (trueValue || '1') : undefined,
    type,
  }
}

export const Section = (options) => {
  return {
    intro: options.intro,
    outro: options.outro,
    type: options.type || 'form',
    label: options.label,
    content: options.intro,
    cols: options.columns ?? options.cols,
    fields: (Array.isArray(options.fields) ? options.fields : []).map(Field),
    buttons: options.buttons,
  };
}

export const Frame = (options) => {
  const res = {...options};
  if (!res.sections) {
    res.sections = [];
  }
  if (res.fields?.length) {
    const {fields, intro, outro, columns, label} = options;
    res.sections.push({
      fields: (Array.isArray(fields) ? fields : []),
      label,
      intro,
      outro,
      columns,
    })
    res.fields = undefined;
  }
  res.sections = res.sections.map(_ => Section(_))
  return res;
};
