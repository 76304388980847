<template>
  <component v-bind:is="layoutComponent.name" :key="layoutComponent.name">
    <App ref="app"/>
  </component>
</template>

<script>
  import Embedded from './Embedded.vue';
  import App from '../core/src/App.vue'

  export default {
    name: 'Main',
    components: {
      App,
      Embedded
    },
    data() {
      return {
        layoutComponent: this.$root.layoutComponent
      }
    },
    computed: {
      form() {
        return this.$refs.app.form;
      }
    }
  }
</script>
