<template>
    <div class="md-field md-theme-default own">
        <md-checkbox
                :name="id"
                :id="id"
                ref="native"
                v-model="model"
                :falseValue="falseValue"
                :trueValue="trueValue"
                :disabled="disabled"
                @change="$emit('input', model)"
        ><span v-html="$t(label)"></span></md-checkbox>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          model: this.value || this.falseValue,
        }
      },
      watch: {
        value(value) {
          this.model = value;
        }
      },
      props: {
        id: String,
        label: {},
        value: {},
        disabled: Boolean,
        trueValue: {},
        falseValue: {},
        description: {},
      },
    }
</script>
<style>
    .md-field.own {
        margin: 0 !important;
        padding: 0 !important;
    }
    .md-field.own::after {
        display: none;
    }

    .md-field.own label {
        top: 0 !important;
    }

    .md-field.own label {
        pointer-events: initial;
    }
</style>
