<template>
    <div class="md-field md-theme-default md-sign-wrapper" :style="getStyle">
      <label v-html="$t(label)"></label>
      <VueSignaturePad
        ref="signaturePad"
        class="md-sign"
        :options="{ onBegin, onEnd }"
        :scaleToDevicePixelRatio="false"
      />
      <span class="md-error">{{$t(description || 'Validation.genericError')}}</span>
    </div>
</template>
<script>
  export default {
    name: 'SignPadField',
    props: {
      label: String,
      id: String,
      description: String,
      width: {
        type: String,
        default: '500px'
      },
      height: {
        type: String,
        default: '400px'
      },
    },
    mounted () {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
      }
    },
    destroyed () {
      this.$refs.signaturePad && this.$refs.signaturePad.lockSignaturePad();
    },
    data() {
      return {
        signatureData: null,
      }
    },
    computed: {
      getStyle() {
        const res = {};
        if (this.width) {
          res['--sign-wrapper-width'] = this.width;
        }
        if (this.height) {
          res['--sign-wrapper-height'] = this.height;
        }
        return res;
      }
    },
    methods: {
      onBegin() {
        this.$refs.signaturePad.resizeCanvas();
      },
      async onEnd() {
        this.signatureData = await this.save();
        this.$emit('input', this.signatureData);
      },
      async save() {
        const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
        return isEmpty ? null : {
          name: this.id,
          type: 'image/png',
          signature: true,
          data: await (await fetch(data)).blob(),
        }
      },
      clear() {
        this.$refs.signaturePad.clearSignature();
      },
      getFiles() {
        return this.signatureData ? [this.signatureData] : [];
      }
    }
  }
</script>
<style scoped>
   .md-sign-wrapper {
      margin: 15px 0px;
      width: var(--sign-wrapper-width);
      height: var(--sign-wrapper-height);
    }
    .md-field > label {
      top: -5px !important;
    }

    /* .md-field.md-theme-default.md-invalid:after {
      background: white;
    } */
    .md-sign {
      background: white;
    }
</style>
