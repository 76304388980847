import { Common } from './common';

export const LayoutEmbedded = 'embedded';

export class Embedded extends Common {
  constructor(options) {
    const layout = {name: LayoutEmbedded, component: () => import('../Embedded.vue')};
    super(options, layout)
  }
}
