import {Frame} from './components';

const Common = (options) => {
  const {style, buttons, ...frameData} = options;
  const res = {...options};
  if (!res.frames?.length) {
    res.frames = [frameData];
  }
  res.frames = res.frames.map(_ => Frame(_))
  return res;
}

const Form = (options) => Common({style: 'form', ...options});
const Chat = (options) => Common({style: 'chat', ...options});
const Quiz = (options) => Common({style: 'quiz', ...options});
const Wizzard = (options) => Common({style: 'wizzard', ...options});
const Tabs = (options) => Common({style: 'tabs', ...options});

export default {
  Common,
  Form,
  Chat,
  Quiz,
  Wizzard,
  Tabs
}
