<template>
  <div v-show="$root.open">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Embedded'
}
</script>

<style>

</style>