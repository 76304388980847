<template>
    <md-app>
        <md-app-content>
            <LangPicker />
            <div style="text-align: center" v-if="logo">
              <img :src="logo" style="max-width: 50vw; max-height: 10vh"/>
            </div>
            <component v-bind:is="widgetComponent"></component>
        </md-app-content>
    </md-app>
</template>

<script>
  import WizzardWidget from './widgets/WizzardWidget';
  import FormWidget from './widgets/FormWidget.vue';
  import TabsWidget from './widgets/TabsWidget.vue';
  import SelectField from './components/SelectField';
  import LangPicker from './components/LangPicker';
  import {FORM_WIDGET, QUIZ_WIDGET, TABS_WIDGET, WIZZARD_WIDGET} from './widgets/constants';

  export default {
    name: 'App',
    components: {
      SelectField,
      LangPicker,
      FormWidget,
      TabsWidget,
      WizzardWidget,
    },
    computed: {
      logo () { return this.$root.config.logo; },
      langs () { return this.$root.config.languages || []; },
      style() { return this.$root.config.widget.style || FORM_WIDGET; },
      frames() { return this.$root.config.widget.frames || []; },
      widgetComponent() {
        if (this.frames.length === 1) {
          if (this.style === QUIZ_WIDGET) {
            return null; //TODO;
          }
          return FormWidget;
        } else if (this.frames.length > 1) {
          if (this.style === WIZZARD_WIDGET) {
            return WizzardWidget;
          }
          return TabsWidget;
        }
        return null;
      }
    },
    methods: {
      handleLocale(locale) {
        this.$i18n.locale = locale;
        this.$forceUpdate();
      }
    }
  }
</script>
