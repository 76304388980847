<template>
    <div>
        <template v-for="(section, index) in step.sections">
            <div class="wrapper">
                <div class="md-title" v-html="$t(section.label)" v-if="section.label"></div>
                <p v-if="section.intro" v-html="$t(section.intro)"></p>
                <Form
                        v-if="section.type === 'form'"
                        :section="section"
                        :key="index"
                        :form="form"
                        ref="validate"
                        v-model="sections[index]"
                        @input="$emit('input', done)"
                ></Form>
                <div v-else-if="section.type === 'recaptcha'">
                    <vue-recaptcha
                            :sitekey="section.public_key"
                            :loadRecaptchaScript="true"
                            @expired="() => {sections[index] = false; $emit('input', done)}"
                            @verify="valid => {sections[index] = valid; $emit('input', done)}"
                    ></vue-recaptcha>
                </div>
                <div v-else-if="section.type === 'html'">
                  <p v-for="p in getHtmlFor(section.content)" v-html="p"></p>
                </div>
                <Social v-else-if="section.type === 'social'" :buttons="section.buttons"></Social>
                <div v-else>{{section}}</div>
                <p v-if="section.outro" v-html="$t(section.outro)"></p>
            </div>
        </template>
    </div>
</template>

<script>
    import Form from './Form'
    import Social from './Social'
    import VueRecaptcha from 'vue-recaptcha';

    export default {
      name: 'Step',
      components: {
        Form,
        Social,
        VueRecaptcha
      },
      data() {
        return {
          sections: this.step.sections.map(({type}) => type !== 'form'),
        }
      },
      props: {
        step: Object,
        form: Object,
        value: Boolean,
      },
      computed: {
        done() {
          return this.sections.every(x => x);
        },
        valid() {
          return this.$refs.validate.every(x => x.valid !== false);
        }
      },
      methods: {
        validate() {
          this.$refs.validate.forEach(x => x.validate());
        },
        getHtmlFor(content) {
          content = this.$t(content);
          if (!Array.isArray(content)) {
            content = [content];
          }
          return content;
        },

        getFiles() {
          return [].concat(...(this.$refs.validate ?? []).map(x => x?.getFiles?.() ?? []))
        }
      }
    }
</script>

<style>
    .wrapper {
        margin: 32px -12px 16px;
        max-width: 100%;
    }
    .md-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .md-title {
        margin-bottom: 16px;
    }
</style>
