<template>
    <div class="tiles-field">
        <label :for="id">{{$t(label)}}</label>
        <div class="md-layout md-gutter">
            <template v-for="(option, index) in options">
                <md-radio
                        :key="index"
                        :name="id"
                        @change="$emit('input', model)"
                        :value="option.value"
                        v-model="model"
                        :disabled="option.disabled"
                        :title="$t(option.hint)"
                        :class="`md-layout-item md-xsmall-size-100 md-size-${size}`"
                >
                    <div class="img-wrapper"><img
                            :src="option.img"
                            :title="$t(option.hint || option.label)"
                            :style="`max-width: ${width || '50px'}; max-height: ${height || '50px'}`"
                    /></div>
                    <label class="md-title">{{ $t(option.label) }}</label>
                    <div class="md-subheading" v-if="option.description" v-html="$t(option.description)"></div>
                </md-radio>
            </template>
        </div>
        <span class="md-error" v-if="false">{{$t(description || 'Validation.genericError')}}</span>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          model: this.value,
        }
      },
      watch: {
        value(value) {
          this.model = value;
        }
      },
      props: {
        id: String,
        label: {},
        disabled: Boolean,
        value: {},
        options: Array,
        width: String,
        height: String,
        description: {},
      },
      computed: {
        size() {
          const num = Object.keys(this.options).length;
          return num === 3 ? 33 : 5 * Math.max(5, 20 / num);
        }
      }
    }
</script>

<style>
    .tiles-field {
        margin: 16px 0;
    }

    .tiles-field.md-invalid {
        border: 2px solid var(--md-theme-default-fieldvariant, #ff1744);
    }

    .tiles-field .md-layout-item .md-radio-label {
        width: 100%;
        text-align: center;
    }

    .tiles-field .img-wrapper {
        margin-bottom: 16px;
    }

    .tiles-field .img-wrapper img {
        border: 2px solid transparent;
    }
    .tiles-field .md-radio {
        margin: 0 !important;
    }
    .tiles-field .md-radio .md-radio-label {
        height: auto !important;
        text-align: center;
        margin: 0;
        padding: 32px 0 !important;
    }
    .tiles-field .md-radio .md-radio-container {
        display: none;
    }

    .tiles-field .md-checked .md-title {
        /*font-weight: bold !important;*/
    }
    .tiles-field .md-checked .md-radio-label {
        background-color: var(--md-theme-default-primary);
    }
</style>
