<template>
  <md-tabs
    class="md-transparent"
    md-alignment="fixed"
    :md-active-tab="active"
    md-dynamic-height
    @md-changed="onTabChange"
  >
    <md-tab
      v-for="(step, index) in steps"
      :key="index"
      :id="step.id"
      :md-label="$t(step.label)"
    >
      <Step
        :step="step"
        :form="form"
        v-model="state[index]"
        :ref="`step_${index}`"
      ></Step>
      <div style="text-align: right">
        <md-button v-for="(button, index) in getCurrentButtons(step.id)"
          :key="index"
          @click="onButtonClick(button)"
          :class="['md-raised', button.color ? `md-${button.color}` : 'md-primary']"
        >
          {{$t(button.label)}}
          &nbsp;
          <i v-if="button.icon" :class="button.icon"></i>
        </md-button>
      </div>
    </md-tab>
  </md-tabs>
</template>

<script>
  import Step from '../components/Step';

  export default {
    name: 'FormWidget',
    components: {
      Step,
    },
    data() {
      return {
        active: this.$root.config.widget.frames[0]?.id,
        sending: false,
        sent: false,
        form: {... (this.$root.initial || {})},
        state: this.$root.config.widget.frames.map(() => false),
      }
    },
    computed: {
      steps () { return this.$root.config.widget.frames },
      buttons() { return this.$root.config.widget.buttons; },
      submit() { return this.buttons.find(_ => _.request) },
      nextButton() { return this.buttons.find(_ => _.action === 'next') },
      prevButton() { return this.buttons.find(_ => _.action === 'prev') },
      done() { return this.state.every(x => x) },
      formdata() {
        const data = new FormData();
        Object.entries(this.form).forEach(([key, value]) => data.append(key, value));
        this.getFiles().forEach((file, idx) => data.append(`__file_${idx}`, file, file.name));
        return data;
      },
      activeTabIndex() { return this.getTabIndex(this.active); }
    },
    methods: {
      getCurrentButtons(id) {
        const buttons = [];
        if (this.steps.findIndex(({id: _}) => _ === id) > 0 && this.prevButton) {
          buttons.push(this.prevButton);
        }
        const frame = this.steps.find(_ => _.id === id);
        if (frame?.buttons) {
          buttons.push(...frame.buttons);
        }
        if (!this.isLast(id) && this.nextButton) {
          buttons.push(this.nextButton);
        }
        if (this.isLast(id)) {
          buttons.push(this.submit);
        }
        return buttons;
      },
      onTabChange(_) {
        if (_ === this.active) {
          return;
        }
        const tabIndex = this.getTabIndex(_);
        if (tabIndex < this.activeTabIndex) {
          this.active = _;
          return;
        }
        this.validate(this.active, this.activeTabIndex);
      },
      onButtonClick(button) {
        if (button.action === 'close') {
          this.$root.open = false;
          return
        }

        const tabIndex = this.activeTabIndex;
        if (button.action === 'prev') {
          this.active = (this.steps[tabIndex - 1] || {}).id || this.active;
          return;
        }

        if (button.action === 'next' && !this.isLast(tabIndex)) {
          this.onTabChange((this.steps[tabIndex + 1] || {}).id);
          return;
        }

        if (button.request) {
          this.validate(this.active, this.activeTabIndex, button.request)
          return;
        }
      },
      getTabIndex(tab) {
        return this.steps.findIndex(({id: _}) => _ === tab)
      },
      isLast(id) {
        const current = this.steps.findIndex(({id: _}) => _ === id);
        return !(current + 1 < this.steps.length);
      },
      validate(id, index, requestData) {
        if (this.state[id] == null) {
          this.state[id] = true;
          this.$refs[`step_${index}`].forEach(x => x.validate());

          setTimeout(() => this.validate(id, index), 500);
          return;
        }

        const isStepReady = (this.isLast(id) ? this.done : this.state[index]);
        if (!isStepReady) {
          return;
        }

        const current = this.steps.findIndex(({id: _}) => _ === id);
        this.active = (this.steps[current + 1] || {}).id || this.active;

        if (requestData) {
          this.makeRequest(requestData);
        }
      },
      async makeRequest(requestData) {
        this.sending = true;

        await this.$root.events?.onSubmit?.(this.form, this.formdata);

        if (requestData && this.$http) {
          const files = this.getFiles();

          const {url, method = 'post', headers, params = {}, mode, envelope} = requestData;

          if (files.length > 0 && mode === 'json') {
            console.warn('using multipart/form-data');
          }

          const form = mode === 'json' && files.length < 1 ? (envelope ? {[envelope]: this.form} : this.form) : this.formdata;

          try {
            await this.$http?.[method.toLowerCase()](url
                + (url.indexOf('?') >= 0 ? '&' : '?') + 'language=' + this.$root.$i18n.locale,
                form, {headers, params});

            this.$root.events?.onSuccess?.();
            requestData?.onSuccess?.();
          } catch (e) {
            if (typeof this.$root.events?.onError === 'function') {
              this.$root.events?.onError(e);
              requestData?.onFailure?.();
            } else {
              throw e;
            }
          }
        }
        this.sending = false;
        this.sent = true;
      },
      getFiles() {
        return [].concat(...([].concat(...Object.values(this.$refs))).map(x => x?.getFiles?.() ?? []))
      }
    }
  }
</script>
