<template>
    <md-field>
        <label :for="id">{{$t(label)}}</label>
        <md-select
          class="select-field"
          :name="id"
          :id="id"
          :autocomplete="id"
          v-model="model"
          ref="native"
          @input="$emit('input', $refs.native.model)"
          :disabled="disabled"
        >
            <md-option :value="option.value" :key="index" v-for="(option, index) in options">
                <!-- <img v-if="option.img" :href="option.img" /> -->
                <!-- <span>{{ $t(option.label) || option.value }}</span> -->
                {{ $t(option.label) || option.value }}
            </md-option>
        </md-select>
        <span class="md-helper-text" v-if="helperText">{{$t(helperText)}}</span>
        <span class="md-error">{{$t(description || 'Validation.genericError')}}</span>
    </md-field>
</template>

<script>
    export default {
      data() {
        return {
          model: this.value,
        }
      },
      watch: {
        value(value) {
          this.model = value;
        }
      },
      props: {
        id: String,
        label: {},
        disabled: Boolean,
        value: {},
        options: Array,
        description: {},
        helperText: {},
      }
    }
</script>
