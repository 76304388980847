<template>
  <div>
    <Step
      :step="currentFrame"
      :form="form"
      v-model="currentState"
      ref="currentFrame"
    ></Step>
    <div style="text-align: right;">
      <md-button v-for="(button, index) in buttons"
        :key="index"
        @click="onButtonClick(button)"
        :class="['md-raised', button.color ? `md-${button.color}` : 'md-primary']"
      >
        {{$t(button.label)}}
        &nbsp;
        <i v-if="button.icon" :class="button.icon"></i>
      </md-button>
    </div>
  </div>
</template>

<script>
  import Step from '../components/Step';

  export default {
    name: 'FormWidget',
    components: {
      Step,
    },
    data() {
      return {
        sending: false,
        sent: false,
        form: {... (this.$root.initial || {})},
        currentState: false,
      }
    },
    computed: {
      widgetConfig() { return this.$root.config.widget; },
      currentFrame () { return this.widgetConfig.frames[0] },
      valid() { return this.currentState },
      buttons() {return this.currentFrame.buttons || this.widgetConfig.buttons || []},
      formdata() {
        const data = new FormData();
        Object.entries(this.form).forEach(([key, value]) => data.append(key, value));
        this.getFiles().forEach((file, idx) => data.append(`__file_${idx}`, file, file.name));
        return data;
      },
    },
    methods: {
      getEnvelopedFormdata(envelope) {
        if (!envelope) {
          return this.formdata;
        }

        const data = new FormData();
        Object.entries(this.form).forEach(([key, value]) => data.append(`${envelope}[${key}]`, value));
        this.getFiles().forEach((file, idx) => data.append(`__file_${idx}`, file, file.name));
        return data;
      },
      async onButtonClick(button) {
        this.validate()

        await sleep(100);
        if (!this.valid) {
          return
        }

        if (button.request) {
          await this.submitRequest(button.request);
        } else if (button.action && typeof button.action === 'function') {
          button.action(this.form, this.formdata)
        } else if (button.action === 'close') {
          this.$root.open = false;
        }
      },
      validate() {
        const res = this.$refs.currentFrame.validate();
        console.log('Validation', res, this.currentState);
        return this.currentState;
      },
      async onSubmitEvent() {
        if (typeof this.$root.events?.onSubmit !== 'function') {
          return
        }
        const result = this.$root.events.onSubmit(this.form, this.formdata);
        if (result instanceof Promise || typeof result?.then === 'function') {
          await new Promise((res, rej) => {
            result.then(res)?.catch?.(rej);
          });
        }
      },
      async submitRequest(requestData) {
        this.sending = true;

        console.log('onSubmitEvent');
        await this.onSubmitEvent();

        if (requestData && this.$http) {
          console.log('Sending');
          const files = this.getFiles();

          const {url, method = 'post', headers, params = {}, mode, envelope} = requestData;

          if (files.length > 0 && mode === 'json') {
            console.warn('using multipart/form-data');
          }
          const form = mode === 'json' && files.length < 1 ? (envelope ? {[envelope]: this.form} : this.form) : this.getEnvelopedFormdata(envelope);

          console.log('request', mode, envelope, form);

          try {
            const response = await this.$http?.[method.toLowerCase()](url
                + (url.indexOf('?') >= 0 ? '&' : '?') + 'language=' + this.$root.$i18n.locale,
                form, {headers, params});

            this.$root.events?.onSuccess?.(response);
            requestData.handler?.(response);
            requestData.onSuccess?.(response);
          } catch (e) {
            if (typeof this.$root.events?.onError === 'function') {
              this.$root.events?.onError(e);
              requestData.onFailure?.(e);
            } else {
              throw e;
            }
          } finally {
            this.sending = false;
          }
        }
        // this.sent = true;
      },

      getFiles() {
        return [].concat(...([].concat(...Object.values(this.$refs))).map(x => x?.getFiles?.() ?? []))
      }
    }
  }

  function sleep(delay) {
    return new Promise((r) => setTimeout(r, delay));
  }
</script>
