<template>
  <div class="md-layout" style="flex-direction: row-reverse" v-if="langs.length">
    <SelectField
      :value="$i18n.locale"
      :options="langs"
      @input="handleLocale"
      class="md-layout-item md-small-size-100 md-size-20"
    ></SelectField>
  </div>
</template>

<script>
  import SelectField from './SelectField';
  
  export default {
    name: 'LandPicker',
    components: {
      SelectField,
    },
    computed: {
      langs () { return this.$root.config.languages || []; },
    },
    methods: {
      handleLocale(locale) {
        this.$i18n.locale = locale;
        this.$forceUpdate();
      }
    }
  }
</script>
<style>
  .lang-picker {
      text-align: right;
  }
</style>