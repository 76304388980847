<template>
    <div>
        <button v-for="button in buttons" :class="`md-button ${getClass(button.class)}`">
            <i :class="`fab fa-${getInfo(button.type)}`"></i> {{ getTitle(button.type) }}
        </button>
    </div>
</template>
<script>
    export default {
      props: {
        buttons: {},
        values: {},
      },
      methods: {
        getInfo(type) {
          return type;
        },
        getClass(type) {
          return type;
        },
        getTitle(type) {
          switch (type) {
            case 'facebook': return 'Facebook';
            case 'linkedin': return 'LinkedIn';
          }
          return type;
        }
      }
    }
</script>
<style>
    .facebook {
        background-color: blue;
        color: white;
    }
    .linkedin {
        background-color: green;
        color: white;
    }
</style>
